import React, { useState } from "react"
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';

export default function Home() {
  const [value, setvalue] = useState(1)
  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setvalue(newValue);
      }}
    >
      <BottomNavigationAction label="Games" icon={<VideogameAssetIcon />} />
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Help" icon={<HelpIcon />} />
    </BottomNavigation>
  )
}
